import React, {useEffect} from 'react';
import * as THREE from 'three';


const textureLoader = new THREE.TextureLoader();

export const useTextureLoader = (path: string) => {

  const [loadResult, setLoadResult] = React.useState<THREE.Texture>();
  const onLoad = React.useRef((loadResult: THREE.Texture) => {
    loadResult.name = path;
    setLoadResult(loadResult);
  });

  useEffect(() => {
    textureLoader.load(path, onLoad.current);
  }, [path]);

  return loadResult;
};
