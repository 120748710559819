import { ThreeJSCanvas } from 'components/ThreeJSCanvas';
import { GuiContextProvider } from 'games/club-penguin/GuiContext';
import { BearsOnTheWater } from 'games/club-penguin/scenes/BearsOnTheWater';
import React from 'react';
import { User } from 'types';

interface Props {
  userList: User[];
  onUpdateUserLocation: (location: [number, number]) => void;
}

export const ClubPenguin = (props: Props) => {
  return (
    <ThreeJSCanvas>
      <GuiContextProvider enabled={false}>
        <BearsOnTheWater userList={props.userList} onUpdateUserLocation={props.onUpdateUserLocation} />
      </GuiContextProvider>
    </ThreeJSCanvas>
  );
};
