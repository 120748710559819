import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      bottom: '0',
      left: '0',
      minHeight: '150px',
      minWidth: '300px',
      maxHeight: '',
      maxWidth: '',
      width: '400px',
      height: '400px',
    },
    resizeHandle: {
      position: 'absolute',
      right: '0',
      top: '0',
      width: '20px',
      height: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      pointerEvents: 'auto',
      userSelect: 'none',
    },
  })
);

interface Props {
  initialHeightPx?: number;
  initialWidthPx?: number;
}

export const Resizable: React.FunctionComponent<Props> = (props) => {
  const { initialHeightPx = 400, initialWidthPx = 400 } = props;
  const classes = useStyles(props);

  const rootElem = React.useRef<HTMLDivElement>(null);
  const resizeHandleElem = React.useRef<HTMLDivElement>(null);

  const heightRef = React.useRef(initialHeightPx);
  const widthRef = React.useRef(initialWidthPx);
  const x = React.useRef(0);
  const y = React.useRef(0);

  const startResize = React.useCallback((event: React.MouseEvent) => {
    x.current = event.screenX;
    y.current = event.screenY;
  }, []);

  const resize = React.useCallback((event: MouseEvent) => {
    const deltaX = event.screenX - x.current;
    const deltaY = event.screenY - y.current;
    widthRef.current += deltaX;
    heightRef.current -= deltaY;
    if (!rootElem.current) return;
    rootElem.current.style.width = widthRef.current + 'px';
    rootElem.current.style.height = heightRef.current + 'px';
    x.current = event.screenX;
    y.current = event.screenY;
  }, []);

  React.useEffect(() => {
    return () => {
      document.body.removeEventListener('mousemove', resize);
    };
  }, [resize]);

  return (
    <div className={classes.root} ref={rootElem}>
      <div
        ref={resizeHandleElem}
        onMouseDown={(event) => {
          startResize(event);
          document.body.addEventListener('mousemove', resize);
          document.body.addEventListener('mouseup', () => {
            document.body.removeEventListener('mousemove', resize);
          });
        }}
        className={classes.resizeHandle}
      >
        <SvgIcon fontSize='small'>
          <rect width='24' height='24' fill='none' rx='0' ry='0' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.00265 19.6001L5.01598 19.5999H9.24271C9.57271 19.5999 9.8427 19.3299 9.8427 18.9999C9.8427 18.6699 9.57271 18.3999 9.24271 18.3999H6.4428L18.4027 6.44V9.2399C18.4027 9.5699 18.6727 9.8399 19.0027 9.8399C19.3327 9.8399 19.6027 9.5699 19.6027 9.2399V4.9999C19.6027 4.851 19.5477 4.71431 19.4571 4.60913C19.4462 4.59575 19.4348 4.58272 19.4226 4.57007C19.2874 4.42898 19.1004 4.37429 18.9204 4.3999H14.7627C14.4327 4.3999 14.1627 4.6699 14.1627 4.9999C14.1627 5.3299 14.4327 5.5999 14.7627 5.5999H17.5428L5.60271 17.54V14.7599C5.60271 14.4299 5.3327 14.1599 5.0027 14.1599C4.6727 14.1599 4.40271 14.4299 4.40271 14.7599V18.9353C4.38699 19.1068 4.44364 19.2855 4.57265 19.4201C4.62013 19.4676 4.67389 19.5056 4.7308 19.5343C4.79491 19.5672 4.8656 19.5888 4.94025 19.5967C4.96115 19.5989 4.98199 19.6001 5.00265 19.6001Z'
            fill='#ffffff'
          />
        </SvgIcon>
      </div>
      {props.children}
    </div>
  );
};
