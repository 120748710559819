import React from 'react';

interface Props {
  fallback: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
