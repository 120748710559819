import {useTextureLoader} from 'hooks/useTextureLoader';
import React from 'react';
import {ReactThreeFiber} from 'react-three-fiber';
import * as THREE from 'three';

type Props = Partial<ReactThreeFiber.Object3DNode<THREE.Mesh, typeof THREE.Mesh>>;

const textureDirectory = `${process.env.PUBLIC_URL}/assets/textures/`;
const waterNormalsPath = `${textureDirectory}waternormals.jpg`;

export const Platform = (props: Props) => {
  const waterTexture = useTextureLoader(waterNormalsPath);

  return (
    <mesh
      name='platform'
      receiveShadow={true}
      position={new THREE.Vector3(0, -5, 0)}
      {...props}
    >
      <boxBufferGeometry
        attach='geometry'
        args={[36, 10, 24]}
      />
      <meshStandardMaterial
        attach='material'
        normalMap={waterTexture}
        color={'rgb(141,141,150)'}
        // bumpMap={waterTexture}
        roughness={0.75}
        metalness={0}
      />
    </mesh>
  );
};
