import { BlackBear } from 'games/club-penguin/character-models/BlackBear';
import { PandaBear } from 'games/club-penguin/character-models/PandaBear';
import { PolarBear } from 'games/club-penguin/character-models/PolarBear';
import React from 'react';
import { useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import { AnimalType } from 'types';

interface Props {
  id: string;
  name: string;
  targetLocation: [number, number];
  animal: AnimalType;
}

const bearSpeed = 10;

export const UserBear = (props: Props) => {
  const playerRef = React.useRef<THREE.Object3D>();
  const playerTargetRef = React.useRef<THREE.Object3D>();
  const [playerLocation, setPlayerLocation] = React.useState<[number, number, number]>([0, 0.001, 0]);
  const [playerRotation] = React.useState<[number, number, number]>([0, 0, 0]);

  useFrame((state, delta) => {
    if (!playerRef.current) return;
    // @ts-ignore for debugging
    if (!window.playerRef) window.playerRef = playerRef.current;
    const targetVector = new THREE.Vector3(props.targetLocation[0], 0.001, props.targetLocation[1]);
    const distanceToGo = playerRef.current.position.distanceTo(targetVector);
    if (distanceToGo < 0.1) return;

    const distanceToMove = bearSpeed * delta;

    playerRef.current.lookAt(targetVector.x, 0, targetVector.z);
    playerRef.current.translateOnAxis(new THREE.Vector3(0, 0, -1), -distanceToMove);
    const currentPlayerPosition = playerRef.current.position.toArray();
    setPlayerLocation([currentPlayerPosition[0], currentPlayerPosition[1], currentPlayerPosition[2]]);
  });

  return (
    <React.Fragment>
      {{
        [AnimalType.PolarBear]: <PolarBear ref={playerRef} position={playerLocation} rotation={playerRotation} />,
        [AnimalType.BlackBear]: <BlackBear ref={playerRef} position={playerLocation} rotation={playerRotation} />,
        [AnimalType.PandaBear]: <PandaBear ref={playerRef} position={playerLocation} rotation={playerRotation} />,
        [AnimalType.GryleBear]: <PandaBear ref={playerRef} position={playerLocation} rotation={playerRotation} />,
        [AnimalType.GreyBear]: <PandaBear ref={playerRef} position={playerLocation} rotation={playerRotation} />,
      }[props.animal] || <BlackBear ref={playerRef} position={playerLocation} rotation={playerRotation} />}
      <axesHelper position={playerLocation} scale={[1, 1, 1]} />
      <axesHelper
        name='playerTarget'
        ref={playerTargetRef}
        position={[props.targetLocation[0], 0.001, props.targetLocation[1]]}
        scale={[1, 1, 1]}
      />
    </React.Fragment>
  );
};
