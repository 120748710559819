import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CircularProgress, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontFamily: "'Freckle Face', cursive",
    },
    spinner: {
      position: 'absolute',
      marginBottom: theme.spacing(8),
    },
    loadingText: {
      fontSize: '2em',
    },
  })
);

interface Props {}

export const LoadingProgress = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={250} thickness={0.3} className={classes.spinner} />
      <div className={classes.loadingText}>loading</div>
    </div>
  );
};
