export enum AnimalType {
  BlackBear,
  PolarBear,
  PandaBear,
  GreyBear,
  GryleBear,
}

export interface User {
  id: string;
  name: string;
  targetLocation: [number, number];
  animalType?: AnimalType;
}
