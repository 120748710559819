import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { AnimalSelect } from 'components/AnimalSelect';
import { ChatWindow } from 'components/ChatWindow';
import { LoadingProgress } from 'components/LoadingProgress';
import { ErrorBoundary } from 'ErrorBoundary';
import * as firebase from 'firebase/app';
import { ClubPenguin } from 'games/club-penguin';
import React from 'react';
import io from 'socket.io-client';
import { AnimalType, User } from 'types';
import './App.css';
import { ThemeProvider, Theme, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const materialUiTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#aab3ff',
      main: '#7884cc',
      dark: '#47589b',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#e6ceff',
      main: '#b39ddb',
      dark: '#836fa9',
      contrastText: '#FFF',
    },
    type: 'dark',
  },

  typography: {
    // fontFamily: ''
  },
});

const gameRoomServerDomain = process.env.REACT_APP_GAME_ROOM_SERVICE_DOMAIN;

const App: React.FC = () => {
  const [userList, setUserList] = React.useState<User[]>([]);
  const [isConnectedToServer, setIsConnectedToServer] = React.useState<boolean>(false);
  const socketRef = React.useRef<typeof io['Socket']>();

  React.useEffect(() => {
    socketRef.current = io(`https://${gameRoomServerDomain}`, {
      reconnection: true,
      path: '/game-room-service/socket.io',
    });

    (async () => {
      if (!socketRef.current) return;
      const currentUser = firebase.auth().currentUser;
      if (currentUser !== null) {
        const idToken = await currentUser.getIdToken();

        document.cookie = `frostbaseIdToken=${idToken}; samesite=strict; domain=${window.location.host}; secure;`;

        socketRef.current.on('connect', function() {
          if (!socketRef.current) return;
          setIsConnectedToServer(true);
          socketRef.current.emit('join', { name: currentUser.displayName || currentUser.email }, function(
            err: unknown
          ) {
            if (err) {
              alert(err);
            }
          });
        });

        socketRef.current.on('disconnect', function() {
          console.log('Disconnected from server');
          setIsConnectedToServer(false);
        });

        socketRef.current.on('updateUserList', function(users: User[]) {
          setUserList(users);
        });
      }
    })().catch((error) => {
      console.error(error);
    });

    return () => {
      if (!socketRef.current) return;
      socketRef.current.disconnect();
    };
  }, []);

  const currentUser = userList.find((u) => u.id === socketRef?.current?.id);

  if (!isConnectedToServer) {
    return <LoadingProgress />;
  }

  if (typeof socketRef.current === 'undefined') {
    return <LoadingProgress />;
  }

  if (typeof currentUser === 'undefined') {
    return <LoadingProgress />;
  }

  if (typeof currentUser.animalType === 'undefined') {
    return (
      <AnimalSelect
        onSelectAnimal={(animalType) => {
          if (typeof socketRef.current === 'undefined') return;

          socketRef.current.emit('updatePlayerAnimal', { animalType }, function(err: unknown) {
            if (err) {
              alert(err);
            }
          });
        }}
      />
    );
  }

  return (
    <ThemeProvider theme={materialUiTheme}>
      <CssBaseline />
      <div className='App'>
        <div className='app-ui'>
          <h3 className='header'>penguin club</h3>
          <FormControl className='animal-select' fullWidth={false} size='small' style={{ marginBottom: '16px' }}>
            <InputLabel>Animal Type</InputLabel>
            <Select
              disabled={typeof currentUser === 'undefined'}
              value={typeof currentUser?.animalType === 'undefined' ? '' : currentUser?.animalType}
              onChange={(event) => {
                if (typeof socketRef.current === 'undefined') return;
                const targetValue = event?.target?.value;
                if (typeof targetValue === 'undefined') return;
                if (typeof targetValue !== 'number') return;
                if (!(targetValue in AnimalType)) return;

                socketRef.current.emit('updatePlayerAnimal', { animalType: targetValue }, function(err: unknown) {
                  if (err) {
                    alert(err);
                  }
                });
              }}
            >
              <MenuItem value={AnimalType.BlackBear}>Black</MenuItem>
              <MenuItem value={AnimalType.GreyBear}>Grey</MenuItem>
              <MenuItem value={AnimalType.GryleBear}>Gryle</MenuItem>
              <MenuItem value={AnimalType.PolarBear}>Polar</MenuItem>
              <MenuItem value={AnimalType.PandaBear}>Panda</MenuItem>
            </Select>
          </FormControl>
          <div className='user-list'>
            <u>Online</u>
            {userList.map((user) => (
              <div key={user.id}>
                {user.name}: {typeof user.animalType !== 'undefined' ? AnimalType[user.animalType] : 'Selecting animal'}
              </div>
            ))}
          </div>
        </div>
        <ChatWindow socketConnection={socketRef.current} userList={userList} />
        <div className='cp-container'>
          <ErrorBoundary
            fallback={
              <span role='img' aria-label='penguin'>
                🐧🐧🐧🐧🐧🐧🐧
              </span>
            }
          >
            <React.Suspense fallback={<LoadingProgress />}>
              <ClubPenguin
                userList={userList}
                onUpdateUserLocation={(location) => {
                  if (typeof socketRef.current === 'undefined') return;

                  socketRef.current.emit('updatePlayerLocation', { location: location }, function(err: unknown) {
                    if (err) {
                      alert(err);
                    }
                  });
                }}
              />
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
