import { BlackBear } from 'games/club-penguin/character-models/BlackBear';
import { PandaBear } from 'games/club-penguin/character-models/PandaBear';
import { PolarBear } from 'games/club-penguin/character-models/PolarBear';
import React from 'react';
import { Canvas } from 'react-three-fiber';
import * as THREE from 'three';
import { AnimalType } from 'types';

interface Props {
  onSelectAnimal: (animalType: AnimalType) => void;
}

export const AnimalSelect = (props: Props) => {
  const [hoveredAnimal, setHoveredAnimal] = React.useState<AnimalType>(AnimalType.PolarBear);
  return (
    <Canvas
      camera={{
        aspect: 1920 / 542,
        fov: 40,
        position: [0, 1, 10],
        near: 0.05,
        far: 10000,
      }}
      gl={{
        antialias: true,
        logarithmicDepthBuffer: true,
        powerPreference: 'high-performance',
        // alpha: false,
      }}
      // gl2={true}
      shadowMap={{
        enabled: true,
        autoUpdate: true,
        type: THREE.VSMShadowMap,
      }}
    >
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <PolarBear
        position={[2.5, 0, 0]}
        rotation={[0, 0.25, 0]}
        scale={hoveredAnimal === AnimalType.PolarBear ? [1.5, 1.5, 1.5] : [1, 1, 1]}
        onPointerOver={() => setHoveredAnimal(AnimalType.PolarBear)}
        onClick={() => props.onSelectAnimal(AnimalType.PolarBear)}
      />
      <BlackBear
        position={[0, 0, 0]}
        rotation={[0, 0.5, 0]}
        scale={hoveredAnimal === AnimalType.BlackBear ? [1.5, 1.5, 1.5] : [1, 1, 1]}
        onPointerOver={() => setHoveredAnimal(AnimalType.BlackBear)}
        onClick={() => props.onSelectAnimal(AnimalType.BlackBear)}
      />
      <PandaBear
        position={[-2.5, 0, 0]}
        rotation={[0, 0.75, 0]}
        scale={hoveredAnimal === AnimalType.PandaBear ? [1.5, 1.5, 1.5] : [1, 1, 1]}
        onPointerOver={() => setHoveredAnimal(AnimalType.PandaBear)}
        onClick={() => props.onSelectAnimal(AnimalType.PandaBear)}
      />
    </Canvas>
  );
};
