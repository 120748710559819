import { Button } from '@material-ui/core';
import { Resizable } from 'components/Resizable';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import io from 'socket.io-client';
import { Message, User } from 'types';

interface Props {
  socketConnection: typeof io['Socket'];
  userList: User[];
}

export const ChatWindow = (props: Props) => {
  const [messageList, setMessageList] = React.useState<Message[]>([]);
  const [chatInput, setChatInput] = React.useState<string>('');
  const chatMessagesElemRef = React.useRef<HTMLDivElement>(null);
  const chatInputElemRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    props.socketConnection.on('newMessage', function(message: Message) {
      setMessageList((prevState) => [...prevState, message]);
      if (chatMessagesElemRef.current)
        chatMessagesElemRef.current.scrollTo({
          top: chatMessagesElemRef.current.scrollHeight,
        });
    });
  }, [props.socketConnection]);

  const sendChatMessage = React.useCallback(() => {
    if (chatInput === '') return;
    props.socketConnection.emit(
      'createMessage',
      {
        text: chatInput,
      },
      function() {
        setChatInput('');
      }
    );
  }, [chatInput, props.socketConnection]);

  useHotkeys(
    'enter',
    () => {
      if (!chatInputElemRef.current) return;
      if (document.activeElement !== chatInputElemRef.current) {
        chatInputElemRef.current.focus();
      } else {
        chatInputElemRef.current.blur();
        sendChatMessage();
      }
    },
    {
      keydown: true,
      keyup: false,
      filter: () => true,
    },
    [sendChatMessage]
  );

  useHotkeys(
    'escape',
    () => {
      if (!chatInputElemRef.current) return;
      if (document.activeElement === chatInputElemRef.current) {
        setChatInput('');
        chatInputElemRef.current.blur();
      }
    },
    {
      keydown: true,
      keyup: false,
      filter: () => true,
    }
  );

  return (
    <div className='chat-window'>
      <Resizable>
        <div className='chat-messages' ref={chatMessagesElemRef}>
          {messageList.map((message, index) => (
            <div key={index} className='chat-message'>
              [{new Date(message.createdAt).toLocaleTimeString()}]{' '}
              {props.userList.filter((u) => u.id === message.from)[0]?.name}: {message.text}
            </div>
          ))}
        </div>
      </Resizable>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (document.activeElement !== chatInputElemRef.current) {
            sendChatMessage();
          }
        }}
      >
        <div className='chat-input'>
          <input
            type='text'
            value={chatInput}
            ref={chatInputElemRef}
            style={chatInput.length === 0 ? { userSelect: 'none' } : {}}
            placeholder={messageList.length === 0 ? 'Click here or press enter to chat' : undefined}
            onChange={(event) => {
              setChatInput(event.currentTarget.value);
            }}
          />
          <Button color='secondary' onClick={sendChatMessage}>
            Send
          </Button>
        </div>
      </form>
    </div>
  );
};
