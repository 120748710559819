import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import {firebaseuiInstance} from './index';

export const firebaseAuthentication = (element: Element) => {

  const uiConfig = {
    siteName: 'frostbase',
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    // Terms of service url.
    // tosUrl: '<your-tos-url>',
    // Privacy policy url.
    // privacyPolicyUrl: '<your-privacy-policy-url>'
    callbacks: {
      signInSuccessWithAuthResult: function (authResult: unknown, redirectUrl: unknown) {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        window.history.pushState({}, '', '/');
        return false;
      },
      signInFailure: async (error: firebaseui.auth.AuthUIError) => {
        console.log('sign in failure :(', error);
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        const loaderElement = document.getElementById('firebaseui-auth-loader');
        if (loaderElement !== null) loaderElement.style.display = 'none';
      },
    },
  };

  // The start method will wait until the DOM is loaded.
  firebaseuiInstance.start(element, uiConfig);
};
