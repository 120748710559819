import React from 'react';
import { Canvas } from 'react-three-fiber';
import * as three from 'three';

interface Props {
  children: React.ReactNode;
}

export const ThreeJSCanvas: React.FunctionComponent<Props> = (props) => {
  return (
    <Canvas
      camera={{
        aspect: 1920 / 542,
        fov: 40,
        position: [28.8, 15.1, 44.8],
        near: 0.05,
        far: 10000,
      }}
      gl={{
        antialias: true,
        logarithmicDepthBuffer: true,
        powerPreference: 'high-performance',
        // alpha: false,
      }}
      // gl2={true}
      shadowMap={{
        enabled: true,
        autoUpdate: true,
        type: three.VSMShadowMap,
      }}
    >
      {props.children}
    </Canvas>
  );
};
