import {useDracoLoader} from 'hooks/useDracoLoader';
import {degreesToRadians} from 'library/degreesToRadians';
import React, {useEffect, useRef, useState} from 'react';
import {useFrame, useThree, ReactThreeFiber} from 'react-three-fiber';
import * as THREE from 'three';

interface OwnProps {
}

type Props = OwnProps & Partial<ReactThreeFiber.Object3DNode<THREE.Group, typeof THREE.Group>>;

const assetDirectory = `${process.env.PUBLIC_URL}/assets/`;
const resourcePath = `${assetDirectory}bear/scene.drc`;

export const PandaBear = React.forwardRef<THREE.Object3D | undefined, Props>(function PolarBear({ children, ...props }: Props, ref: React.Ref<THREE.Object3D | undefined>) {
  const { scene } = useThree();
  const loadResult = useDracoLoader(resourcePath);
  const actions = useRef<{ [key: string]: THREE.AnimationAction | undefined }>();
  const [mixer, setMixer] = useState(() => new THREE.AnimationMixer(new THREE.Object3D()));
  const groupRef = useRef<any>();
  const [rootJoint, setRootJoint] = React.useState<any>();
  const [material, setMaterial] = React.useState<any>();
  const [geometry, setGeometry] = React.useState<any>();
  const [skeleton, setSkeleton] = React.useState<any>();
  const guiParams = useRef({
    x_red: 0,
    y_green: 0.91,
    z_blue: 0,
  });

  useEffect(() => {
    if (!loadResult) return;
    const rootNode = loadResult.scene.getObjectByName('RootNode');
    if (!rootNode) return;
    rootNode.updateMatrix();
    let bearObjects = rootNode.children.map(o => o.children[0].children[0]);
    bearObjects = bearObjects.map<THREE.Object3D>((bearObject, bearIndex) => {
      const skinnedMesh = bearObject.getObjectByProperty('type', 'SkinnedMesh') as THREE.SkinnedMesh;
      bearObject.name = (skinnedMesh.material as THREE.Material).name;
      return bearObject;
    });
    console.log(bearObjects.map(b => b.name));
    const polarBear = bearObjects.find((bearMesh) => bearMesh.name === 'Panda');
    if (!polarBear) return;
    const polarBearMesh = polarBear.getObjectByProperty('type', 'SkinnedMesh') as THREE.SkinnedMesh;
    if (!Array.isArray(polarBearMesh.material)) {
      // @ts-ignore
      polarBearMesh.material.skinning = true;
    }
    setMaterial(polarBearMesh.material);
    setRootJoint(polarBear.getObjectByName('_rootJoint'));
    setGeometry(polarBearMesh.geometry);
    setSkeleton(polarBearMesh.skeleton);
    setMixer(new THREE.AnimationMixer(polarBearMesh));
  }, [loadResult, scene]);

  const updateBearPosition = React.useCallback(() => {
    if (!groupRef.current) return;
    groupRef.current.position.fromArray([guiParams.current.x_red, guiParams.current.y_green, guiParams.current.z_blue]);
  }, []);

  React.useEffect(() => {
    updateBearPosition();
  }, [updateBearPosition]);

  useFrame((state, delta) => mixer.update(delta));

  useEffect(() => {
    if (!groupRef.current) return;
    const firstAnimation = loadResult?.animations[0];
    if (!firstAnimation) return;

    const take1Action = mixer.clipAction(firstAnimation);
    actions.current = {
      'Take 001': take1Action,
    };
    // take1Action.setLoop(THREE.LoopRepeat, Infinity);
    // take1Action.play();

    return () => loadResult?.animations.forEach(clip => mixer.uncacheClip(clip));
  }, [loadResult, mixer]);

  if (!(rootJoint && material && geometry && skeleton)) return null;

  const scale = 0.008;

  return (
    <group name="PolarBear" ref={ref} {...props}>
      {children}
      <group
        ref={groupRef}
        position={[guiParams.current.x_red, guiParams.current.y_green, guiParams.current.z_blue]}
        rotation={[degreesToRadians(-90), 0, degreesToRadians(-90)]} scale={[scale, scale, scale]}
      >
        <primitive object={rootJoint}/>
        <skinnedMesh
          material={material}
          geometry={geometry}
          skeleton={skeleton}
          name="PolarBearMesh"
          castShadow={true}
        />
      </group>
    </group>
  );
});
