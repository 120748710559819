import React, {useEffect} from 'react';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
dracoLoader.preload();
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

export const useDracoLoader = (path: string) => {

  const [loadResult, setLoadResult] = React.useState<GLTF>();
  const onLoad = (loadResult: GLTF) => {
    setLoadResult(loadResult);
  };

  useEffect(() => {
    gltfLoader.load(path, onLoad);
  }, [path]);

  return loadResult;
};
