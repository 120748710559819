import React from 'react';
import {GUI, Folder, Item} from 'three/examples/jsm/libs/dat.gui.module.js';
import {useFrame} from 'react-three-fiber';

type IGuiContext = GUI;

const noopFolder: Folder = {
  add: (): Item & { name: () => void } => {
    return {
      name: () => {
      },
      onChange: (eventHandler) => {
      },
    };
  },
  name: () => {
  },
  open: () => {
  },
};

const noopGuiContext: IGuiContext = {
  addFolder: () => noopFolder,
  close: () => {
  },
  destroy: () => {
  },
  removeFolder: () => {
  },
  hide: () => {
  },
  updateDisplay: () => {
  },
};

const defaultGuiContext: IGuiContext = {
  addFolder: (): Folder => {
    throw new Error('No GUI Context');
  },
  close: () => {
    throw new Error('No GUI Context');
  },
  destroy: () => {
    throw new Error('No GUI Context');
  },
  removeFolder: () => {
    throw new Error('No GUI Context');
  },
  hide: () => {
    throw new Error('No GUI Context');
  },
  updateDisplay: () => {
    throw new Error('No GUI Context');
  },
};

const GuiContext = React.createContext<IGuiContext>(defaultGuiContext);

const globalGui = new GUI();
globalGui.close();
globalGui.hide();

interface Props {
  enabled?: boolean;
}

export const GuiContextProvider: React.FunctionComponent<Props> = ({ children, enabled = true }) => {
  const [gui] = React.useState<IGuiContext>(enabled ? globalGui : noopGuiContext);

  React.useEffect(() => {
    return () => {
      gui.hide();
      gui.destroy();
    };
  }, [gui]);

  useFrame(() => {
    gui.updateDisplay();
  });

  return (
    <GuiContext.Provider value={gui}>
      {children}
    </GuiContext.Provider>
  );

};

export const useGuiContext = () => {
  return React.useContext(GuiContext);
};

