import { CameraControls } from 'games/club-penguin/CameraControls';
import { Ocean } from 'games/club-penguin/object-models/Ocean';
import { Platform } from 'games/club-penguin/object-models/Platform';
import { PostProcessingEffects } from 'games/club-penguin/PostProcessingEffects';
import { UserBear } from 'games/club-penguin/UserBear';
import React from 'react';
import { useThree } from 'react-three-fiber';
import { User } from 'types';

interface Props {
  userList: User[];
  onUpdateUserLocation: (location: [number, number]) => void;
}

export const BearsOnTheWater = (props: Props) => {
  const { gl } = useThree();
  React.useEffect(() => {
    gl.gammaFactor = 2.2;
    gl.physicallyCorrectLights = true;
  }, [gl.gammaFactor, gl.physicallyCorrectLights]);

  const updateUserLocation = React.useCallback(
    (x: number, z: number) => {
      props.onUpdateUserLocation([x, z]);
    },
    [props]
  );

  return (
    <React.Fragment>
      <ambientLight />
      <Ocean />
      <CameraControls />
      <PostProcessingEffects />
      {props.userList.map((user) => {
        if (typeof user.animalType === 'undefined') return null;

        return (
          <UserBear
            key={user.id}
            animal={user.animalType}
            id={user.id}
            name={user.name}
            targetLocation={user.targetLocation}
          />
        );
      })}
      <Platform
        onPointerDown={(event) => {
          // button 0 is: Left Mouse, Touch Contact, Pen contact (with no modifier buttons pressed)
          if (event.button === 0) {
            const [x, , z] = event.point.toArray();

            updateUserLocation(x, z);
            // console.log("u got da corona :)");
          }
        }}
      />
    </React.Fragment>
  );
};
