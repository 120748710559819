import React from 'react';
import {extend, useFrame, useThree} from 'react-three-fiber';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import {SMAAPass} from 'three/examples/jsm/postprocessing/SMAAPass';
import {SSAOPass} from 'three/examples/jsm/postprocessing/SSAOPass';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import * as THREE from 'three';

extend({ EffectComposer, RenderPass, UnrealBloomPass, SMAAPass, SSAOPass });

const bloom = {
  strength: 0.25,
  radius: 0.5,
  threshold: 0.925,
};

export const PostProcessingEffects = () => {
  const { gl, scene, camera, size } = useThree();
  const composer = React.useRef<EffectComposer>();
  const windowVector2 = React.useRef(new THREE.Vector2(size.width, size.height));
  const occlusionPass = React.useRef<SSAOPass>();

  React.useEffect(() => {
    if (!composer.current) return;
    composer.current.setSize(size.width, size.height);
  }, [size]);

  React.useEffect(() => {
    if (occlusionPass.current) {
      // @ts-ignore
      occlusionPass.current.output = SSAOPass.OUTPUT.Default;
      // @ts-ignore
      occlusionPass.current.kernelRadius = 3;
      // @ts-ignore
      // occlusionPass.current.kernelSize = 1;
      // @ts-ignore
      occlusionPass.current.minDistance = 0.02;
      // @ts-ignore
      occlusionPass.current.maxDistance = 0.3;
    }
  }, [size]);

  useFrame(() => {
    if (composer.current) composer.current.render();
  }, 1);

  return (
    // @ts-ignore
    <effectComposer ref={composer} args={[gl]}>
      // @ts-ignore
      <renderPass attachArray="passes" args={[scene, camera]}/>
      {/*// @ts-ignore*/}
      {/*<sSAOPass ref={occlusionPass} attachArray="passes" args={[scene,camera]}/>*/}
      // @ts-ignore
      <unrealBloomPass attachArray="passes" args={[windowVector2, bloom.strength, bloom.radius, bloom.threshold]}/>
      // @ts-ignore
      <sMAAPass attachArray="passes" args={[size.width, size.height]}/>
      // @ts-ignore
    </effectComposer>
  );
};
