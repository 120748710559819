import {useGuiContext} from 'games/club-penguin/GuiContext';
import React from 'react';
import {Water} from 'three/examples/jsm/objects/Water';
import {Sky} from 'three/examples/jsm/objects/Sky';
import * as three from 'three';
import {useThree, useFrame} from 'react-three-fiber';

const textureDirectory = `${process.env.PUBLIC_URL}/assets/textures/`;
const waterNormalsPath = `${textureDirectory}waternormals.jpg`;

const updateSunPosition = (water: Water, sky: Sky, light: three.DirectionalLight, params: {
  distance: number,
  inclination: number,
  azimuth: number,
}) => {
  const theta = Math.PI * (params.inclination - 0.5);
  const phi = 2 * Math.PI * (params.azimuth - 0.5);

  light.position.x = params.distance * Math.cos(phi);
  light.position.y = params.distance * Math.sin(phi) * Math.sin(theta);
  light.position.z = params.distance * Math.sin(phi) * Math.cos(theta);

  sky.material.uniforms['sunPosition'].value = light.position.copy(light.position);
  // @ts-ignore
  water.material.uniforms['sunDirection'].value.copy(light.position).normalize();
};

export const Ocean = () => {
  const guiParametersRef = React.useRef({
    distance: 200,
    inclination: 0.49,
    azimuth: 0.205,
  });
  const threeCanvasContext = useThree();
  const { scene } = threeCanvasContext;
  // @ts-ignore
  window.threeCanvasContext = threeCanvasContext;
  const [cubeCam, setCubeCam] = React.useState<three.CubeCamera>();
  const [primWater, setPrimWater] = React.useState<Water>();
  const [primSky, setPrimSky] = React.useState<Sky>();
  const [primLight, setPrimLight] = React.useState<three.DirectionalLight>();
  const gui = useGuiContext();

  React.useEffect(() => {
    const textureLoader = new three.TextureLoader();

    const sunColor = 0xffffe0;

    const light = new three.DirectionalLight(sunColor, 0.8);

    const waterGeometry = new three.PlaneBufferGeometry(10000, 10000);

    textureLoader.load(waterNormalsPath, (responseTexture) => {
      responseTexture.wrapS = responseTexture.wrapT = three.RepeatWrapping;

      const water = new Water(waterGeometry, {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: responseTexture,
        alpha: 1,
        sunDirection: light.position.clone().normalize(),
        sunColor: sunColor,
        waterColor: 0x001e1b,
        distortionScale: 2,
        fog: false,
      });
      water.rotation.x = -Math.PI / 2;
      const skybox = new Sky();

      skybox.material.uniforms['turbidity'].value = 10;
      skybox.material.uniforms['rayleigh'].value = 2;
      skybox.material.uniforms['luminance'].value = 1;
      skybox.material.uniforms['mieCoefficient'].value = 0.005;
      skybox.material.uniforms['mieDirectionalG'].value = 0.8;
      const skyScale = 100000;
      skybox.geometry.scale(skyScale, skyScale, skyScale);

      const cubeCamera = new three.CubeCamera(0.1, 1, Math.max(threeCanvasContext.size.height, threeCanvasContext.size.width));
      // cubeCamera.renderTarget.texture.generateMipmaps = true;
      // cubeCamera.renderTarget.texture.minFilter = three.LinearMipMapLinearFilter;

      // @ts-ignore
      cubeCamera.update(threeCanvasContext.gl, skybox);

      light.castShadow = true;

      water.name = 'water';
      setPrimWater(water);
      light.name = 'skylight';
      setPrimLight(light);
      skybox.name = 'skybox';
      setPrimSky(skybox);
      cubeCamera.name = 'cubeCamera';
      setCubeCam(cubeCamera);

      updateSunPosition(water, skybox, light, guiParametersRef.current);

    });
  }, [gui, threeCanvasContext.gl, threeCanvasContext.size.height, threeCanvasContext.size.width]);

  React.useEffect(() => {
    if (!primLight) return;
    if (!primWater) return;
    if (!primSky) return;

    try {
      let folder = gui.addFolder('Sky');
      folder.add(guiParametersRef.current, 'inclination', 0, 0.5, 0.0001)
        .onChange(() => updateSunPosition(primWater, primSky, primLight, guiParametersRef.current));

      folder.add(guiParametersRef.current, 'azimuth', 0, 1, 0.0001)
        .onChange(() => updateSunPosition(primWater, primSky, primLight, guiParametersRef.current));

      //@ts-ignore
      let uniforms = primWater.material.uniforms;
      let folder2 = gui.addFolder('Water');
      folder2.add(uniforms.distortionScale, 'value', 0, 8, 0.1).name('distortionScale');
      folder2.add(uniforms.size, 'value', 0.1, 10, 0.1).name('size');
      folder2.add(uniforms.alpha, 'value', 0.9, 1, .001).name('alpha');

      return () => {
        gui.removeFolder(folder);
        gui.removeFolder(folder2);
      };
    } catch (error) {
      console.error(error);
    }
  }, [primLight, primSky, primWater, gui]);

  React.useEffect(() => {
    if (!cubeCam) return;
    // @ts-ignore
    scene.background = cubeCam.renderTarget;
  }, [cubeCam, scene.background]);

  useFrame((frameState, delta) => {
    if (primWater) {
      // @ts-ignore
      primWater.material.uniforms['time'].value += delta * 0.4;
    }
    if (cubeCam && primSky) {
      // @ts-ignore
      // cubeCam.update(frameState.gl, primSky);
    }
  }, 0);

  if (!primWater) return null;
  if (!primSky) return null;
  if (!primLight) return null;

  return (
    <React.Fragment>
      <primitive object={primWater} position={[0, -10.011, 0]}/>
      <primitive object={primSky}/>
      <primitive object={primLight}/>
    </React.Fragment>
  );
};
