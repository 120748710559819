import {degreesToRadians} from 'library/degreesToRadians';
import React, {useRef} from 'react';
import {extend, useFrame, useThree} from 'react-three-fiber';
import * as three from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

interface Props {
}

export const CameraControls = (props: Props) => {
  const controlsRef = useRef<OrbitControls>();
  const { camera, gl } = useThree();

  useFrame((frameState) => {
    controlsRef.current && controlsRef.current.update();
  }, 0);
  return (
    <React.Fragment>
      // @ts-ignore
      <orbitControls
        ref={controlsRef}
        args={[camera, gl.domElement]}
        enableRotate={true}
        enableDamping={true}
        enablePan={true}
        // screenSpacePanning={false}
        mouseButtons={{ MIDDLE: three.MOUSE.PAN, RIGHT: three.MOUSE.ROTATE }}
        maxDistance={300}
        minDistance={0}
        minPolarAngle={degreesToRadians(30)}
        maxPolarAngle={degreesToRadians(90)}
        // minAzimuthAngle={degreesToRadians(-50)}
        // maxAzimuthAngle={degreesToRadians(50)}
      />
    </React.Fragment>
  );
};
