import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: 'AIzaSyAacz1kEWz6loGBJqU3UkdgC1aUdJ1xprw',
  authDomain: 'frostbase1.firebaseapp.com',
  databaseURL: 'https://frostbase1.firebaseio.com',
  projectId: 'frostbase1',
  storageBucket: 'frostbase1.appspot.com',
  messagingSenderId: '108067944359',
  appId: '1:108067944359:web:20631a63516a0b0db26882',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence({ synchronizeTabs: true }).then();
