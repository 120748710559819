import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {firebaseAuthentication} from './firebaseAuthentication';
import './initFirebase';
import * as serviceWorker from './serviceWorker';

export const firebaseuiInstance = new firebaseui.auth.AuthUI(firebase.auth());

const rootElement = document.getElementById('root');
if (rootElement === null) throw Error();
const firebaseUiContainer = document.getElementById('firebaseui-auth-container');
if (firebaseUiContainer === null) throw Error();

firebase.auth().onAuthStateChanged((firebaseUser) => {
  if (firebaseUser === null) {
    firebaseAuthentication(rootElement);
  } else {
    ReactDOM.render(<App/>, rootElement);
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
